import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentStatusEnum } from 'src/models/enums/constant.enum';

@Component({
  selector: 'app-buy-listing-report-card',
  templateUrl: './buy-listing-report-card.component.html',
  styleUrls: ['./buy-listing-report-card.component.scss']
})
export class BuyListingReportCardComponent {

  @Input() product: any;

  contentStatusEnum = ContentStatusEnum;

  constructor(public translate: TranslateService) { }

}
