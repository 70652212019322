<a [routerLink]="product?.status == contentStatusEnum.APPEAL ? (product?._id + '/activities') : (!product?.product?.deleted_at ? product?._id : null)"
    [queryParams]="product?.status == contentStatusEnum.APPEAL ? { status: product?.status } : null"
    class="product-card card mb-xl-5 overflow-hidden position-relative">

    <div *ngIf="product?.product?.deleted_at" class="position-absolute top-0 end-0 bg-danger text-white badge"
        style="border-bottom-right-radius: 0; border-top-left-radius: 0;">
        {{ "STATUS.DELETED" | translate }}
    </div>
    <div class="d-xl-flex justify-content-between">
        <div class="card-body d-flex gap-5 border-bottom">
            <div>
                <img [src]="product?.product?.shop?.photo_url | token" class="avatar avatar__sm"
                    alt="Poster profile image">
            </div>
            <div class="card-info">
                <div class="card-title d-flex gap-3">
                    <h5 class="text-black fw-bold mb-0 text-truncate lh-base">{{ product?.product?.shop?.name }}</h5>
                    <span style="min-width: fit-content;">{{ product?.product?.created_at | amLocale: translate.currentLang | amDateFormat: 'DD-MM-YYYY h:mm A' }}</span>
                </div>
                <p class="card-text product-title truncate fw-semibold text-black mb-1">{{ product?.product?.title }}</p>
                <p class="card-description card-text truncate" style="--line-clamp: 2;">{{ product?.product?.description }}</p>
                <div class="card-text d-grid d-xl-flex align-items-center gap-3 border-top border-light pt-4">
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="warning" path="assets/icons/alert-circle.svg" style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.NEW_REPORT" | translate }} <span class="text-primary fw-bold">{{ product?.new_total_report ?? 0 }}</span></div>
                    </div>
                    <div class="vr d-none d-xl-inline-block"></div>
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="danger" path="assets/icons/alert-circle.svg" style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.TOTAL_REPORT" | translate }} <span class="text-primary fw-bold">{{ product?.total_report }}</span></div>
                    </div>
                    <div class="vr d-none d-xl-inline-block"></div>
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="primary" path="assets/icons/status.svg" style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.STATUS" | translate }}
                            <span class="badge status {{ product?.status | lowercase }}">
                                {{ ("STATUS." + product?.status) | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center pe-xl-6">
            <img [src]="product?.product?.photo_urls[0] | token: 'assets/icons/default-gallery.svg'"
                class="post-photo flex-grow-1 flex-xl-grow-0" alt="post photo">
        </div>
    </div>
</a>