import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentStatusEnum } from 'src/models/enums/constant.enum';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent {

  @Input() product: any;

  contentStatusEnum = ContentStatusEnum;

  constructor(public translate: TranslateService) { }

}
