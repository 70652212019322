<form>
    <div class="filter-block d-flex flex-column flex-sm-row align-items-sm-center gap-4 rounded-2 py-2 px-5">
        <input
            type="search"
            [(ngModel)]="params.keyword"
            [ngModelOptions]="{ standalone: true }"
            class="form-control search-form-control flex-grow-1"
            [placeholder]="'FILTERING.SEARCH' | translate" />
        <div class="vr d-none d-sm-block"></div>
    
        <ng-container *ngIf="!hiddenDateRange">
            <app-ngb-date-range-picker (dateRangeSelection)="onDateRangeSelection($event)"></app-ngb-date-range-picker>
            <div class="vr d-none d-sm-block"></div>
        </ng-container>
    
        <button type="submit" class="btn btn-primary justify-content-center" (click)="search()">
            <app-svg-icon path="assets/icons/search.svg"></app-svg-icon>
            {{ "FILTERING.SEARCH" | translate }}
        </button>
    </div>
</form>