import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss']
})
export class PhotoPreviewComponent {

  @Input() photo_urls: string[];
  @Input() photo_url: string;
  @Input() activeIndex: string = '0';

  constructor(public modal: NgbActiveModal) { }

}
