<nav class="navbar-container navbar navbar-expand border-bottom">
    <div class="container-fluid px-0">
        <div class="collapse navbar-collapse">

            <h4 class="fw-bold me-auto mb-0 ms-7 ps-7 ms-lg-0 ps-lg-0 text-primary">
                <span class="d-block px-2">
                    {{ ("NAVBAR." + componentPageHeader) | translate }}
                </span>
            </h4>

            <div class="user-bar d-flex align-items-center" *ngIf="profile">
                <div class="d-flex gap-7">
                    <div class="d-inline-block" ngbDropdown>
                        <img src="assets/icons/globe.svg" ngbDropdownToggle style="cursor: pointer;">
                        <div ngbDropdownMenu class="dropdown-menu-end">

                            <ng-container *ngFor="let item of listLang">
                                <button
                                    ngbDropdownItem
                                    class="lang-item"
                                    [ngClass]="{'active': cookieValue === item.lang}"
                                    (click)="setLanguage(item.lang)">

                                    <img [src]="item.flag" alt="flag" width="18" class="me-5">
                                    <span class="flex-grow-1">{{ item.text }}</span>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="vr me-3"></div>
                </div>
                <div ngbDropdown>
                    <button type="button" class="user-dropdown btn btn-outline-primary border-0 fw-bold"
                        ngbDropdownToggle>
                        <img [src]="profile?.photo_url | token" alt="avatar person" class="avatar border me-3">
                        <span class="d-none d-lg-inline-block">{{ profile | fullName }}</span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                        <a ngbDropdownItem routerLink="/profile">
                            <app-svg-icon path="assets/icons/people-circle.svg"></app-svg-icon>
                            {{ "NAVBAR.LIST.PROFILE" | translate }}
                        </a>
                        <a ngbDropdownItem href="javascript: void(0);" (click)="openConfirmModal()" class="text-danger">
                            <app-svg-icon path="assets/icons/box-arrow-left.svg" class="logout"></app-svg-icon>
                            {{ "NAVBAR.LIST.LOGOUT" | translate }}
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</nav>