import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhotoPreviewComponent } from '../components/photo-preview/photo-preview.component';

@Directive({
  selector: '[previewPhoto]'
})
export class PreviewPhotoDirective {

  @Input('previewPhoto') photo_url: string | string[];
  @Input('activeIndex') activeIndex: number = 0;

  @HostListener('click', ['$event'])
  clickEvent() {

    const modalRef = this.modalService.open(PhotoPreviewComponent, { fullscreen: true, modalDialogClass: 'custom-photo-preview' });
    if (Array.isArray(this.photo_url)) {

      // multiple photos
      modalRef.componentInstance.photo_urls = this.photo_url;
      modalRef.componentInstance.activeIndex = this.activeIndex.toString();
    } else {

      // single photo
      modalRef.componentInstance.photo_url = this.photo_url;
    }
  }

  constructor(el: ElementRef, private modalService: NgbModal) {
    el.nativeElement.style.cursor = 'pointer';
  }

}
