import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageEnum } from 'src/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/services/local-storage.service';

@Pipe({
  name: 'token'
})
export class TokenPipe implements PipeTransform {

  token: string = '';

  constructor(private localStorageService: LocalStorageService) {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
  }

  transform(url: string, defaultUrl?: string, size: string = 's'): string {
    defaultUrl = defaultUrl ?? 'assets/icons/avatar-person.svg';
    if (!url) return defaultUrl;

    return `${url}?token=${this.token}&size=${size}`;
  }

}
