<div class="modal-body text-center">
    <h3 class="pt-5 title"> {{ data?.title | translate }} </h3>
    <div class="d-flex justify-content-center align-items-center flex-column">
        <img [src]="data?.icon" alt="icon" class="img">
        <div style="color: rgba(0, 0, 0, 0.87);"> {{ data?.message | translate }} </div>
    </div>

</div>

<div class="modal-footer justify-content-end">
    <button *ngIf="data?.button == 'delete'" type="button" class="btn btn-success" (click)="modal.close('confirmed')">
        {{ "BUTTON.YES_DELETE" | translate }}
    </button>
    <button *ngIf="isBtnCancel" type="button" class="btn btn-light" ngbAutofocus (click)="modal.dismiss('cancel click')">
        {{ "BUTTON.CANCEL" | translate }}
    </button>
    <button *ngIf="data?.button == 'remove'" type="button" class="btn btn-success" (click)="modal.close('confirmed')">
        <app-svg-icon path="assets/icons/check-circle.svg"></app-svg-icon>
        {{ "BUTTON.CONFIRM" | translate }}
    </button>

</div>