import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngb-date-range-picker',
  templateUrl: './ngb-date-range-picker.component.html',
  styleUrls: ['./ngb-date-range-picker.component.scss']
})
export class NgbDateRangePickerComponent {

  isOpen = false;
  hoveredDate: NgbDate | null = null;

  @ViewChild('datepicker') private datePicker: NgbInputDatepicker;

  @Input() fromDate?: NgbDate | null;
  @Input() toDate?: NgbDate | null;

  @Output() dateRangeSelection = new EventEmitter<{ from: NgbDate, to: NgbDate }>();

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) { }

  private emit(close?: boolean) {
    const dateRange = {
      from: this.fromDate,
      to: this.toDate,
    };

    this.dateRangeSelection.emit(dateRange);

    if (close) {
      this.isOpen = false;
      this.datePicker.close();
    }
  }

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
      this.emit(true);
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

}
