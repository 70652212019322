<div class="main-layout">
    <div class="wrapper">

        <!-- offcanvas -->
        <ng-template #content let-offcanvas>
            <div class="offcanvas-body">
                <app-sidebar></app-sidebar>
            </div>
        </ng-template>
        <button type="button" class="navbar-toggler d-lg-none border rounded" (click)="open(content)">
            <app-svg-icon color="primary" path="assets/icons/toggler.svg"></app-svg-icon>
        </button>

        <!-- sidebar -->
        <div class="drawer-sidebar border-end px-5">
            <app-sidebar></app-sidebar>
        </div>

        <!-- navbar -->
        <div class="drawer-content">
            <app-navbar></app-navbar>

            <!-- pages -->
            <div class="app-container">
                <div class="app-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>

    </div>
</div>