<div class="modal-header">
    <h4 class="modal-title"> {{ "REPORTER_DETAIL.TEXT" | translate }} </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body detail-form reporter-detail-modal">
    <div class="row">
        <div class="content-left col-sm-6 d-flex flex-column gap-5">
            <div class="d-flex align-items-center">
                <img [src]="detail?.reported_by?.photo_url | token" alt="poster profile" class="avatar avatar__lg me-4">
                <div>
                    <h5 class="text-black fw-bold">{{ detail?.reported_by | fullName }}</h5>
                    <div class="d-flex gap-2 align-items-center">
                        <app-svg-icon path="assets/icons/clock.svg"></app-svg-icon>
                        {{ detail?.created_at ? (detail?.created_at | amLocale: translate.currentLang | amTimeAgo) : '' }}
                    </div>
                </div>
            </div>

            <div>
                <div class="main-label"> {{ "REPORTER_DETAIL.REPORT_TYPE" | translate }} </div>
                <div class="d-flex flex-wrap gap-1">
                    <span *ngFor="let type of detail?.report_type" class="badge bg-primary-subtle text-primary">
                        {{ type | translateAPI: translate.currentLang }}
                    </span>
                </div>
            </div>

            <div *ngIf="detail?.reason">
                <div class="main-label"> {{ "REPORTER_DETAIL.REASON" | translate }} </div>
                {{ detail?.reason }}
            </div>
        </div>
        <div class="content-right col-sm-6 mt-7 mt-sm-0">
            <div class="main-label"> {{ "REPORTER_DETAIL.IMAGE" | translate }} </div>

            <ng-container *ngIf="detail?.photo_url; else noImage">
                <div class="position-relative overflow-hidden rounded-3" style="height: 280px;">
                    <div [backgroundImage]="detail?.photo_url | token"></div>
                    <img [src]="detail?.photo_url | token: 'assets/icons/default-gallery.svg' : 'm'"
                        [previewPhoto]="detail?.photo_url ? detail?.photo_url : 'assets/icons/default-gallery.svg'"
                        alt="Gallery" class="gallery detail-photo-url border w-100 position-absolute"
                        style="--height: 100%;" />
                </div>
            </ng-container>
            <ng-template #noImage>
                <img src="assets/icons/default-gallery.svg" alt="Gallery" class="gallery border w-100"
                    style="--height: 280px;" />
            </ng-template>
        </div>
    </div>
</div>