import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ReportType } from 'src/models/report-type';
import { ContentModerationService } from 'src/services/content-moderation.service';
import { ReportTypeService } from 'src/services/report-type.service';
@Component({
  selector: 'app-violated',
  templateUrl: './violated.component.html',
  styleUrls: ['./violated.component.scss']
})
export class ViolatedComponent implements OnInit {

  @Input() content: any;

  form: FormGroup;
  submit!: boolean;
  params = {
    per_page: 999,
    page_number: 1
  };
  reportTypes: ReportType[];
  isLoading!: boolean;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private reportTypeService: ReportTypeService,
    private contentModerationService: ContentModerationService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.getMany();
  }

  initFormGroup() {
    this.form = this.fb.group({
      report_type: [null, Validators.required],
      reason: [null, Validators.required],
      type: ['VIOLATE']
    });
  }

  getMany() {
    this.reportTypeService.getMany(this.params).subscribe({
      next: (res) => {
        this.reportTypes = res.data;
      }
    });
  }

  /**
    * Returns form
  */
  get f() {
    return this.form.controls;
  }

  onSubmit() {

    this.submit = true;

    if (!this.form.valid) return;
    this.isLoading = true;

    const data = { ...this.form.value, ...this.content };
    this.contentModerationService.reportContentActivities(data).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.modal.close(res);
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

}
