import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/models/user';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: Partial<User> | User[], id?: string): string {

    let names = [];

    if (value) {
      let user: Partial<User>;
      if (Array.isArray(value)) {
        user = (value as Partial<User>[]).find(u => u._id == id)
      } else {
        user = value;
      }

      if (user.last_name) {
        names.push(user.last_name);
      }

      if (user.first_name) {
        names.push(user.first_name);
      }

      return names.join(' ');
    }

    return '--';
  }

}
