import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from './pipes/pipes.module';
import { NgbDateRangePickerComponent } from './components/ngb-date-range-picker/ngb-date-range-picker.component';
import { NgbCarouselModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PhotoPreviewComponent } from './components/photo-preview/photo-preview.component';
import { ViolatedComponent } from './components/violated/violated.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuyListingReportCardComponent } from './components/buy-listing-report-card/buy-listing-report-card.component';
import { ReporterDetailComponent } from './components/reporter-detail/reporter-detail.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { ShopReportCardComponent } from './components/shop-report-card/shop-report-card.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AverageReporterComponent } from './components/average-reporter/average-reporter.component';
import { DirectivesModule } from './directives/directives.module';
import { FilteringComponent } from './components/filtering/filtering.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateApiPipe } from './pipes/translate-api.pipe';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { MomentModule } from 'ngx-moment';
import { ReportContentActivitiesComponent } from './components/report-content-activities/report-content-activities.component';



@NgModule({
  declarations: [
    SvgIconComponent,
    PostCardComponent,
    ProductCardComponent,
    ShopReportCardComponent,
    NgbDateRangePickerComponent,
    PhotoPreviewComponent,
    ViolatedComponent,
    BuyListingReportCardComponent,
    ReporterDetailComponent,
    UploadImageComponent,
    ConfirmModalComponent,
    AverageReporterComponent,
    FilteringComponent,
    TranslateApiPipe,
    EmptyDataComponent,
    ReportContentActivitiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    DirectivesModule,
    NgbDatepickerModule,
    NgbCarouselModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    FormsModule
  ],
  exports: [
    SvgIconComponent,
    PostCardComponent,
    ProductCardComponent,
    ShopReportCardComponent,
    BuyListingReportCardComponent,
    NgbDateRangePickerComponent,
    ReporterDetailComponent,
    UploadImageComponent,
    ConfirmModalComponent,
    AverageReporterComponent,
    FilteringComponent,
    TranslateApiPipe,
    EmptyDataComponent,
    ReportContentActivitiesComponent
  ]
})
export class SharedModule { }
