import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent {

  @Output() fileChange = new EventEmitter(null);

  onChangedFile(target: any) {

    const file = target.files[0];

    // check whether the upload is an image
    if (file && !file.type.match('image.*')) return;
    this.fileChange.emit(file);
  }

}
