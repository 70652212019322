import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[backgroundImage]'
})
export class BackgroundImageDirective {

  @Input('backgroundImage') photo_url: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    el.nativeElement.style.cursor = 'pointer';
    el.nativeElement.style.position = 'absolute';

    el.nativeElement.style.width = '100%';
    el.nativeElement.style.height = '100%';
    el.nativeElement.style.filter = 'blur(50px)';
    el.nativeElement.style.transform = 'scale(3)';
  }

  ngOnInit(): void {
    this.renderer.setStyle(
      this.el.nativeElement, 'background-image', `url(${this.photo_url})`
    );
    this.renderer.setStyle(
      this.el.nativeElement, 'background-size', 'cover'
    );
  }

}
