<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title"> {{ "VIOLATED.TEXT" | translate }} </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body detail-form">
        <div class="mb-2">
            <div class="form-label"> {{ "VIOLATED.REPORT_TYPE" | translate }} </div>
            <ng-select [multiple]="true" formControlName="report_type"
                [ngClass]="{'is-invalid': submit && f.report_type.errors}">
                <ng-option *ngFor="let type of reportTypes" [value]="type._id">
                    {{ type | translateAPI: translate.currentLang }}
                </ng-option>
            </ng-select>
            <div class="invalid-feedback">
                {{ "FORM.REQUIRED" | translate }} 
            </div>
        </div>

        <div>
            <div class="form-label"> {{ "VIOLATED.REASON" | translate }} </div>
            <textarea class="form-control" rows="5" formControlName="reason"
                [ngClass]="{'is-invalid': submit && f.reason.errors}"></textarea>
            <div class="invalid-feedback">
                {{ "FORM.REQUIRED" | translate }} 
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-center">
        <button [type]="isLoading ? 'button' : 'submit'" class="btn btn-primary" [disabled]="isLoading">
            <app-svg-icon path="assets/icons/send.svg"></app-svg-icon>
            {{ "BUTTON.SAVE" | translate }} 
        </button>
    </div>
</form>