import { Injectable, Injector } from '@angular/core';
import { BaseDataTable } from 'src/models/data-tables/base.datatable';
import { RequestService } from './request.service';
import { RequestPagination } from 'src/models/request-pagination';

@Injectable({
  providedIn: 'root'
})
export class BaseCrudService<T> {

  public path: string = '';
  protected requestService: RequestService;

  constructor(injector: Injector) {
    this.requestService = injector.get(RequestService);
  }

  create(data: T) {
    return this.requestService.postJSON<T>(this.path, { data });
  }

  createFile(data: T) {
    return this.requestService.postFile<T>(this.path, { data });
  }

  update(_id: string, data: T) {
    delete data['_id'];
    return this.requestService.patchJSON<T>(this.path + '/' + _id, { data });
  }

  put(_id: string, data: T) {
    delete data['_id'];
    return this.requestService.putJSON<T>(this.path + '/' + _id, { data });
  }

  putFile(_id: string, data: T) {
    delete data['_id'];
    return this.requestService.putFile<T>(this.path + '/' + _id, { data });
  }

  getMany(data: RequestPagination) {
    return this.requestService.getJSON<BaseDataTable<T>>(this.path, { data });
  }

  getById(_id: string) {
    return this.requestService.getJSON<T>(this.path + '/' + _id);
  }

  delete(_id: string) {
    return this.requestService.deleteJSON<T>(this.path + '/' + _id);
  }
}
