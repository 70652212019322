import { LoginData } from '../models/data/login.data';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FullNamePipe } from 'src/app/shared/pipes/full-name.pipe';
import { ToastService } from './toast.service';
import { UserRoleEnum } from 'src/models/enums/constant.enum';
import { User } from 'src/models/user';
import { BaseDataTable } from 'src/models/data-tables/base.datatable';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  authStatus: boolean = this.isAuth;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {

    this.setAuth(this.isAuth);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.authStatus != this.isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  getAuth(): Observable<boolean> {
    return this.authChange$.asObservable();
  }

  setAuth(data: boolean) {
    return this.authChange$.next(data);
  }

  login(data: { identifier: string; password: string }): Observable<LoginData> {
    const data1 = {
      identifier: data.identifier,
      password: data.password,
    };
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate', {
        data: data1,
      })
      .pipe(
        map((res) => {

          /**
           * no permission using the system beside role national_manager & admin
           */
          if (
            !res.user.roles.includes(UserRoleEnum.NATIONAL_MANAGER)
            && !res.user.roles.includes(UserRoleEnum.ADMIN)
            && !res.user.roles.includes(UserRoleEnum.MODERATOR)) {
            this.toastService.show('TOAST.NOT_HAVE_PERMISSION', { classname: 'bg-danger text-white' });
          } else {

            /**
             * with permission user
             */
            this.onLoginSuccess(res);
          }

          return res;
        })
      );
  }

  loginWithGoogle(
    data: { token: string },
    profile_image?: string
  ): Observable<LoginData> {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-google', { data })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithFacebook(
    data: { token: string },
    profile_image?: string
  ): Observable<LoginData> {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-facebook', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithCamDigiKey(data: { token: string }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate-camdigikey', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithApple(data: { token: string }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate-apple', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  registerWithApple(data: {
    first_name: string;
    last_name: string;
    token: string;
  }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-apple', { data })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  fullNamePipe = new FullNamePipe();

  onLoginSuccess(res) {
    const token = res.token;
    const tokenExpiresInMillisecond = Number(res.expires_in);
    const tokenExpiresAt = new Date().getTime() + tokenExpiresInMillisecond;
    const userId = res.user._id;
    const fullName = this.fullNamePipe.transform(res.user, '');
    const photoUrl = res.user.photo_url;
    const roles = res?.user?.roles;

    this.localStorageService.set(LocalStorageEnum.token, token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, userId);
    this.localStorageService.set(LocalStorageEnum.user_fullname, fullName);
    this.localStorageService.set(LocalStorageEnum.user_photo_url, photoUrl);
    this.localStorageService.set(LocalStorageEnum.is_login_with_password, (res?.user?.is_login_with_password));
    this.localStorageService.set(LocalStorageEnum.user_roles, JSON.stringify(roles));

    this.localStorageService.set(
      LocalStorageEnum.token_expires_at,
      tokenExpiresAt.toString()
    );
    this.navigateByRole().then(() => {
      window.location.reload();
    });
    this.authChange$.next(true);
  }

  logout() {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.user_fullname);
    this.localStorageService.delete(LocalStorageEnum.user_photo_url);
    this.localStorageService.delete(LocalStorageEnum.shop_id);
    this.localStorageService.delete(LocalStorageEnum.is_login_with_password);
    this.localStorageService.delete(LocalStorageEnum.user_roles);
    this.localStorageService.delete(LocalStorageEnum.token_expires_at);
    this.logoutToken();
    this.markStatusChange();
  }

  logoutToken() {
    return this.requestService.deleteJSON(environment.auth_url + '/logout-token');
  }

  private markStatusChange() {
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }


  get isAuth(): boolean {
    return this.localStorageService?.get(LocalStorageEnum.token) ? true : false;
  }

  /* get isAuth(): boolean {
 
     const currentMillisecond = new Date().getTime();
     const token = this.localStorageService?.get(LocalStorageEnum.token);
     const token_expires_at = Number(this.localStorageService?.get(LocalStorageEnum.token_expires_at));
 
     if (token && (token_expires_at < currentMillisecond)) {
       this.localStorageService.delete(LocalStorageEnum.token);
       this.localStorageService.delete(LocalStorageEnum.token_expires_at);
       this.toastService.show('TOAST.TOKEN_EXPIRED', { classname: 'bg-danger text-white' });
     }
 
     return (token && (token_expires_at > currentMillisecond));
   }*/


  filterUsers(data: any) {
    return this.requestService.getJSON<BaseDataTable<User>>(environment.auth_url + '/users/filter', { data });
  }

  getRoles(): string[] {
    return JSON.parse(decodeURIComponent(this.localStorageService.get(LocalStorageEnum.user_roles)));
  }

  /** These functions are useful for filtering by roles (Address filtering)
   *  there are provinces selection, district selection, commune selection, and village selection
   *
   **/
  hasAccess = (roles: string[]): boolean =>
    _.intersection(this.getRoles(), roles).length > 0;
  isNationalManagerRole = (): boolean => this.hasAccess([UserRoleEnum.NATIONAL_MANAGER]);
  isAdminRole = (): boolean => this.hasAccess([UserRoleEnum.ADMIN]);
  isModerationRole = (): boolean => this.hasAccess([UserRoleEnum.MODERATOR]);

  /** end filtering by role functions */

  /**
 * This function for navigation based on user role
 */
  navigateByRole(): Promise<boolean> {
    if (this.isNationalManagerRole() || this.isAdminRole()) return this.router.navigate(['dashboard']);

    if (this.isModerationRole()) return this.router.navigate(['post']);

    this.toastService.show("TOAST.NO_PERMISSION_ACCESS", { classname: 'bg-danger text-white' });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return this.router.navigate(['login']);
      }, 300);
    });
  }

}
