import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullNamePipe } from './full-name.pipe';
import { TokenPipe } from './token.pipe';
import { RangeCurrencyPipe } from './range-currency.pipe';
import { TelsPipe } from './tels.pipe';



@NgModule({
  declarations: [
    FullNamePipe,
    TokenPipe,
    RangeCurrencyPipe,
    TelsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FullNamePipe,
    TokenPipe,
    RangeCurrencyPipe,
    TelsPipe
  ]
})
export class PipesModule { }
