<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
<div class="modal-body d-flex align-items-center justify-content-center">

    <!-- multiple photos -->
    <ng-container *ngIf="photo_urls?.length > 0">

        <ngb-carousel
            [interval]="0"
            [showNavigationArrows]="photo_urls?.length > 1"
            [showNavigationIndicators]="false"
            [activeId]="activeIndex"
            class="rounded-2 overflow-hidden w-100 h-100">

            <ng-template ngbSlide *ngFor="let photo of photo_urls; let i = index" id="{{i}}">
                <div class="picsum-img-wrapper">
                    <img [src]="photo | token: '' : 'l'" alt="Gallery slide"
                        class="gallery post-gallery d-flex m-auto" />
                </div>
            </ng-template>
        </ngb-carousel>

    </ng-container>

    <!-- single photo -->
    <ng-container *ngIf="photo_url">

        <ngb-carousel
            [interval]="0"
            [showNavigationArrows]="false"
            [showNavigationIndicators]="false"
            class="rounded-2 overflow-hidden w-100 h-100">

            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img [src]="photo_url | token: '' : 'l'" alt="Gallery slide"
                        class="gallery post-gallery d-flex m-auto" />
                </div>
            </ng-template>
        </ngb-carousel>

    </ng-container>
</div>