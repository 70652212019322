<div class="h-100">
    <div class="dp-hidden position-absolute">
        <div class="input-group">
            <input
                name="datepicker"
                class="form-control control-date-picker"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1"
            />
            <ng-template #t let-date let-focused="focused">
                <span
                    class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                >
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
    </div>

    <div class="custom-date-range">
        <app-svg-icon path="assets/icons/calendar.svg" class="me-3" (click)="datepicker.toggle()"></app-svg-icon>
        <div class="date-range-input flex-grow-1">
            <input
                #dpFromDate
                class="form-control"
                [placeholder]="'FILTERING.START_DATE' | translate "
                name="dpFromDate"
                [value]="formatter.format(fromDate)"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                (click)="datepicker.toggle()"
                readonly
            />
            <span class="date-range-input-mirror">{{formatter.format(fromDate)}}</span>
        </div>
        <span class="mx-1">–</span>
        <div class="date-range-input flex-grow-1">
            <input
                #dpToDate
                class="form-control"
                [placeholder]="'FILTERING.END_DATE' | translate "
                name="dpToDate"
                [value]="formatter.format(toDate)"
                (input)="toDate = validateInput(toDate, dpToDate.value)"
                (click)="datepicker.toggle()"
                readonly
            />
            <span class="date-range-input-mirror">{{formatter.format(toDate)}}</span>
        </div>
    </div>
</div>