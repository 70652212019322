import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { environment } from 'src/environments/environment';
import { ReportType } from 'src/models/report-type';

@Injectable({
    providedIn: 'root'
})
export class ReportTypeService extends BaseCrudService<ReportType>{

    constructor(injector: Injector) {
        super(injector);
        this.path = `${environment.portal_url}/admin/report-types`;
    }

}