import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentActivity } from 'src/models/content-activities';
import { LocalStorageEnum } from 'src/models/enums/local-storage.enum';
import { ContentModerationService } from 'src/services/content-moderation.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-report-content-activities',
  templateUrl: './report-content-activities.component.html',
  styleUrls: ['./report-content-activities.component.scss']
})
export class ReportContentActivitiesComponent implements OnInit {

  data: ContentActivity[];
  userPhotoUrl: string;
  
  form: FormGroup;
  isLoading!: boolean;
  
  @Input() contentId: string;
  @Input() contentType: 'post' | 'product' | 'buy_listing' | 'shop' = 'post';

  @ViewChild('myResponse') myResponse: ElementRef;

  constructor(
    private contentModerationService: ContentModerationService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {

    if (!this.contentId) return;
    this.getActivities();
    this.userPhotoUrl = decodeURIComponent(this.localStorageService.get(LocalStorageEnum.user_photo_url));
    this.initFormGroup();
  }

  getActivities() {
    this.contentModerationService.getAllReportContentActivities(this.contentId).subscribe({
      next: (res) => {
        this.data = res.data;
      }
    });
  }

  initFormGroup() {
    this.form = this.fb.group({
      reason: [null, Validators.required],
      type: ["MESSAGE"]
    });
  }

  onSend() {

    if (!this.form.valid) return;
    this.isLoading = true;

    let content = {};
    content[this.contentType] = this.contentId;

    const data = {
      ...this.form.value,
      ...content
    };

    this.contentModerationService.reportContentActivities(data).subscribe({
      next: () => {
        this.isLoading = false;
        this.handleClear();
        this.getActivities();
      },
      error: (err) => {
        this.isLoading = false;
        this.toastService.show(err?.errors?.message, { classname: 'bg-danger text-white' });
      }
    });
  }

  handleClear() {
    // clearing the value
    this.myResponse.nativeElement.value = '';
  }

}
