import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rangeCurrency'
})
export class RangeCurrencyPipe implements PipeTransform {

  transform(
    values: number[] = [],
    currencyCode: string = 'KHR',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '1.0-0',
    locale: string = 'en',
  ): string | null {

    return values?.map(value => formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    )).join(' - ');
  }

}
