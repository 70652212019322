import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentStatusEnum } from 'src/models/enums/constant.enum';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

  @Input() product: any;
  
  contentStatusEnum = ContentStatusEnum;

  constructor(public translate: TranslateService) { }

}
