export default class ConstantEnum {
    public static readonly ACTIVE = 1;
}

export enum ContentStatusEnum {
    PENDING = "PENDING",
    APPEAL = "APPEAL",
    VIOLATED = "VIOLATED",
    APPROVED = "APPROVED"
}

export enum UserRoleEnum {
    NATIONAL_MANAGER = "national_manager",
    PORTAL = "portal",
    ADMIN = "admin",
    MODERATOR = "moderator"
}