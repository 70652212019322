import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { MenuItem } from 'src/models/menu-item';
import { MENU } from './menu';
import { AuthService } from 'src/services/auth.service';
import _ from 'lodash';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  MenuItem: MenuItem[];

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.MenuItem = this.getSideNavByRole();
  }

  // filter SidenavMenu based on user role permission
  getSideNavByRole(): MenuItem[] {
    return _.filter(MENU, (m: MenuItem) => {
      let child: MenuItem[] = _.filter(m.children, (ch: MenuItem) => this.authService.hasAccess(ch.roles));
      m.children = child;

      return this.authService.hasAccess(m.roles);
    });
  }

  isChildActive(children: MenuItem[]): boolean {

    for (let i = 0; i < children.length; i++) {
      const url = typeof children[i].route === 'string' ? children[i].route : this.router.createUrlTree(children[i].route);
      const matchOptions: IsActiveMatchOptions = {
        paths: 'subset',
        matrixParams: 'subset',
        queryParams: 'subset',
        fragment: 'ignored'
      };
      const isActive = this.router.isActive(url, matchOptions);

      if (isActive) {
        return true;
      }
    }
    return false;
  }

}
