import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModal } from 'src/models/confirm-modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() data: ConfirmModal;

  isBtnCancel: boolean;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.data) {
      this.isBtnCancel = this.data?.isBtnCancel ?? true;
    }
  }

}
