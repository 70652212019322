<div class="card">
    <div class="card-body">
        <h6 class="card-title text-primary fw-bold"> {{ "AVERAGE_REPORTER.TEXT" | translate }} </h6>
        <div class="d-flex justify-content-center">
            <canvas id="canvas" class="p-6" style="max-height: 360px; max-width: 360px;">{{chart}}</canvas>
        </div>

        <div class="d-flex flex-column gap-2">
            <ng-container *ngFor="let item of averageReport; let i = index">
                <div class="d-flex justify-content-between p-2 fw-semibold summery-item"
                    style="--color: {{ itemRGB[i] }};">
                    <div>{{ item?.report_type | translateAPI: translate.currentLang }}</div>
                    <div>{{ data[i] + '%' }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center gap-3 mt-6">
    <button type="button" class="btn btn-danger" (click)="violated()">
        <app-svg-icon path="assets/icons/alert-circle.svg"></app-svg-icon>
        {{ "BUTTON.VIOLATED" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="appropriate()" [disabled]="isChanging">
        <app-svg-icon path="assets/icons/check-circle.svg"></app-svg-icon>
        {{ "BUTTON.APPROPRIATE" | translate }}
    </button>
</div>