import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tels'
})
export class TelsPipe implements PipeTransform {
  transform(tels: string[]): string {
    if (!tels) return "STATUS.NA";
    return tels.join(", ") != "" ? tels.join(", ") : "STATUS.NA";
  }
}
