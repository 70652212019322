import { UserRoleEnum } from "src/models/enums/constant.enum";
import { MenuItem } from "src/models/menu-item";

export const MENU: MenuItem[] = [
    {
        title: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: '/assets/icons/dashboard.svg',
        route: '/dashboard',
        roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
    },
    {
        title: 'MENUITEMS.CONTENT_MODERATION.TEXT',
        icon: '/assets/icons/content.svg',
        route: '',
        roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN, UserRoleEnum.MODERATOR],
        children: [
            {
                title: 'MENUITEMS.CONTENT_MODERATION.LIST.POST',
                icon: '',
                route: '/post',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN, UserRoleEnum.MODERATOR]
            },
            {
                title: 'MENUITEMS.CONTENT_MODERATION.LIST.PRODUCT',
                icon: '',
                route: '/product',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN, UserRoleEnum.MODERATOR]
            },
            {
                title: 'MENUITEMS.CONTENT_MODERATION.LIST.BUY_LISTING',
                icon: '',
                route: '/buy-listing',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN, UserRoleEnum.MODERATOR]
            },
            {
                title: 'MENUITEMS.CONTENT_MODERATION.LIST.SHOP',
                icon: '',
                route: '/shop',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN, UserRoleEnum.MODERATOR]
            },
        ]
    },
    {
        title: 'MENUITEMS.KAS_AI_MARKET_TREND.TEXT',
        icon: '/assets/icons/ai.svg',
        route: '/kas-ai-market-trend',
        roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
    },
    {
        title: 'MENUITEMS.SETTINGS.TEXT',
        icon: '/assets/icons/gear.svg',
        route: '',
        roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN],
        children: [
            {
                title: 'MENUITEMS.SETTINGS.LIST.USER_MANAGEMENT',
                icon: '',
                route: 'user-management',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
            },
            {
                title: 'MENUITEMS.SETTINGS.LIST.PRODUCT_CATEGORY',
                icon: '',
                route: 'product-category',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
            },
            {
                title: 'MENUITEMS.SETTINGS.LIST.REPORT_TYPE',
                icon: '',
                route: 'report-type',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
            },
            {
                title: 'MENUITEMS.SETTINGS.LIST.BANK_MANAGEMENT',
                icon: '',
                route: 'bank-management',
                roles: [UserRoleEnum.NATIONAL_MANAGER, UserRoleEnum.ADMIN]
            }
        ]
    },
];