<div class="report-activities card">
    <div class="report-activities__container card-body">

        <!-- list -->
        <div class="report-activities__container report-activities__list">

            <ng-container *ngFor="let item of data; let last = last">
                <div class="report-activities__container report-activities__list">

                    <!-- header -->
                    <div class="d-flex align-items-center gap-2">
                        <div class="d-flex align-items-center gap-4">
                            <img [src]="item?.user?.photo_url | token" alt="user profile"
                                class="avatar avatar__sm img-thumbnail">
                            <h5 class="text-black fw-bold mb-0">{{ item?.user | fullName }}</h5>
                        </div>
                        <div>
                            {{ item?.created_at | amLocale: translate.currentLang | amDateFormat: 'DD-MM-YYYY h:mm A' }}
                        </div>
                    </div>

                    <!-- info -->
                    <div class="info-card card bg-white">
                        <div class="card-body d-flex flex-column align-items-start gap-2 p-5">
                            <div>
                                {{ item?.reason }}
                            </div>
                            <img *ngIf="item?.photo_url"
                                [src]="item?.photo_url | token: 'assets/icons/default-gallery.svg' : 'm'" alt="photo"
                                class="rounded-2 border">
                        </div>
                        <div *ngIf="!last" class="vr"></div>
                    </div>
                </div>
            </ng-container>

        </div>

        <!-- enter your response -->
        <div>
            <hr class="mt-0">
            <div class="card">
                <form [formGroup]="form" (ngSubmit)="onSend()">
                    <div class="card-body d-flex gap-2 align-items-center py-4 px-5">
                        <div class="d-flex gap-4 align-items-center flex-grow-1">
                            <div>
                                <img [src]="userPhotoUrl | token" alt="my profile"
                                    class="avatar avatar__sm img-thumbnail">
                            </div>
                            <input #myResponse type="text" formControlName="reason" class="input-response form-control border-0"
                                [placeholder]="'FORM.ENTER_RESPONSE' | translate">
                        </div>
                        <button [type]="isLoading ? 'button' : 'submit'" class="btn btn-primary" [disabled]="isLoading">
                            <app-svg-icon path="assets/icons/send.svg"></app-svg-icon>
                            {{ "BUTTON.SEND" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>