import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { ContentModeration } from 'src/models/content-moderation';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BaseDataTable } from 'src/models/data-tables/base.datatable';
import { Reporter } from 'src/models/reporter';
import { PreviewContent } from 'src/models/preview-content';
import { AverageReport } from 'src/models/average-report';
import { ContentActivity } from 'src/models/content-activities';

@Injectable({
    providedIn: 'root'
})
export class ContentModerationService extends BaseCrudService<ContentModeration>{

    constructor(injector: Injector) {
        super(injector);
        this.path = `${environment.portal_url}/admin/content_moderation`;
    }

    getAllReportByReporter(content_id: string): Observable<BaseDataTable<Reporter>> {
        return this.requestService.getJSON(`${this.path}/${content_id}/reporter`);
    }

    getPreviewContent(content_id: string): Observable<PreviewContent> {
        return this.requestService.getJSON(`${this.path}/${content_id}/preview`);
    }

    getAverageReport(content_id: string): Observable<AverageReport[]> {
        return this.requestService.getJSON(`${this.path}/${content_id}/average_report`);
    }

    getAllReportContentActivities(content_id: string) {
        return this.requestService.getJSON<BaseDataTable<ContentActivity>>(`${this.path}/${content_id}/report-content-activities`);
    }

    getDetailReportByReportId(report_id: string): Observable<Reporter> {
        return this.requestService.getJSON(`${this.path}/report/${report_id}`);
    }

    reportContentActivities(data: any) {
        return this.requestService.postJSON(`${this.path}/report-content-activities`, { data });
    }

    changeReportedContentStatus(id: string, data: any) {
        return this.requestService.putJSON(`${this.path}/${id}`, { data });
    }

}