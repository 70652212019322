import { AfterViewInit, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Chart from 'chart.js/auto';
import { AverageReport } from 'src/models/average-report';
import { ContentModerationService } from 'src/services/content-moderation.service';
import { ToastService } from 'src/services/toast.service';
import { ViolatedComponent } from '../violated/violated.component';
import { ContentStatusEnum } from 'src/models/enums/constant.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-average-reporter',
  templateUrl: './average-reporter.component.html',
  styleUrls: ['./average-reporter.component.scss']
})
export class AverageReporterComponent implements AfterViewInit {

  @Input() contentId: string;
  @Input() contentType: 'post' | 'product' | 'buy_listing' | 'shop' = 'post';

  chart: any = [];
  labels: string[] = [];
  data: number[] = [];
  backgroundColor = ['#FED236', '#77E843', '#FF5151', '#36a2eb', '#E087FF', '#6C95FF'];
  itemRGB = ['254 210 54', '108 231 50', '255 81 81', '54 162 235', '224 135 255', '108 149 255'];

  averageReport: AverageReport[];
  isChanging!: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contentModerationService: ContentModerationService,
    private toastService: ToastService,
    private modalService: NgbModal,
    public translate: TranslateService
  ) { }

  ngAfterViewInit(): void {
    this.getAverageReport();
  }

  getAverageReport() {
    this.contentModerationService.getAverageReport(this.contentId).subscribe({
      next: (res) => {
        this.averageReport = res;
        this.setChart();
      }
    });
  }

  setChart() {
    this.averageReport?.forEach(element => {
      this.labels.push(element.report_type.name_en);
      this.data.push(element.percentage);
    });

    if (this.data?.length < 0) return;

    this.chart = new Chart('canvas', {
      type: 'pie',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.backgroundColor
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return context.parsed + '%';
              }
            }
          }
        }
      }
    });
  }

  violated() {

    let data = {};
    data[this.contentType] = this.contentId;

    const modalRef = this.modalService.open(ViolatedComponent, { centered: true });
    modalRef.componentInstance.content = data;
    modalRef.result.then((result) => {
      if (result) {
        this.toastService.show('TOAST.REPORT_SUCCESS', { classname: 'bg-success text-white' });
        this.router.navigate(['../../'], { relativeTo: this.route });
      }
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  appropriate() {
    this.isChanging = true;
    this.contentModerationService.changeReportedContentStatus(this.contentId, { status: ContentStatusEnum.APPROVED }).subscribe({
      next: () => {
        this.isChanging = false;
        this.toastService.show('TOAST.APPROVE_SUCCESS', { classname: 'bg-success text-white' });
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      error: () => {
        this.isChanging = false;
      }
    });
  }

}
