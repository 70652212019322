<a [routerLink]="item?.status == contentStatusEnum.APPEAL ? (item?._id + '/activities') : (!item?.shop?.deleted_at ? item?._id : null)"
    [queryParams]="item?.status == contentStatusEnum.APPEAL ? { status: item?.status } : null"
    class="product-card card mb-xl-5 overflow-hidden position-relative">

    <div *ngIf="item?.shop?.deleted_at" class="position-absolute top-0 end-0 bg-danger text-white badge"
        style="border-bottom-right-radius: 0; border-top-left-radius: 0;">
        {{ "STATUS.DELETED" | translate }}
    </div>
    <div class="d-xl-flex justify-content-between">
        <div class="card-body d-flex gap-5">
            <div>
                <img [src]="item?.shop?.photo_url | token" class="avatar avatar__sm" alt="Poster profile image">
            </div>
            <div class="card-info">
                <div class="card-title d-flex gap-3">
                    <h5 class="text-black fw-bold mb-0 text-truncate lh-base">{{ item?.shop?.name }}</h5>
                    <span style="min-width: fit-content;">
                        {{ item?.shop?.created_at | amLocale: translate.currentLang | amDateFormat: 'DD-MM-YYYY h:mm A' }}
                    </span>
                </div>
                <div class="d-flex flex-column gap-1 flex-xl-row gap-xl-7 align-xl-items-center mb-5">
                    <div>{{ "TABLE.FOLLOWER" | translate }} &nbsp; <span class="text-primary">{{ item?.shop?.total_subscribers ?? 0 }}</span></div>
                    <div>{{ "TABLE.LAST_UPDATE" | translate }} &nbsp; <span class="text-primary">{{ item?.shop?.updated_at | date: 'dd-MM-yyyy' }}</span></div>
                </div>
                <div class="card-text d-grid d-xl-flex align-items-center gap-3 border-top border-light pt-4">
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="warning" path="assets/icons/alert-circle.svg"
                                style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.NEW_REPORT" | translate }} <span class="text-primary fw-bold">{{ item?.new_total_report ?? 0 }}</span>
                        </div>
                    </div>
                    <div class="vr d-none d-xl-inline-block"></div>
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="danger" path="assets/icons/alert-circle.svg"
                                style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.TOTAL_REPORT" | translate }} <span class="text-primary fw-bold">{{ item?.total_report }}</span></div>
                    </div>
                    <div class="vr d-none d-xl-inline-block"></div>
                    <div class="d-flex align-items-center gap-2">
                        <div>
                            <app-svg-icon color="primary" path="assets/icons/status.svg"
                                style="--size: 20px;"></app-svg-icon>
                        </div>
                        <div>{{ "TABLE.STATUS" | translate }}
                            <span class="badge status {{ item?.status | lowercase }}">
                                {{ ("STATUS." + item?.status) | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>