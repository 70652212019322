import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewPhotoDirective } from './preview-photo.directive';
import { CountUpDirective } from './count-up.directive';
import { BackgroundImageDirective } from './background-image.directive';



@NgModule({
  declarations: [
    PreviewPhotoDirective,
    CountUpDirective,
    BackgroundImageDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PreviewPhotoDirective,
    CountUpDirective,
    BackgroundImageDirective
  ]
})
export class DirectivesModule { }
