import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Reporter } from 'src/models/reporter';
import { ContentModerationService } from 'src/services/content-moderation.service';

@Component({
  selector: 'app-reporter-detail',
  templateUrl: './reporter-detail.component.html',
  styleUrls: ['./reporter-detail.component.scss']
})
export class ReporterDetailComponent implements OnInit {

  @Input() _id: string;

  detail: Reporter;

  constructor(
    public modal: NgbActiveModal,
    private contentModerationService: ContentModerationService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getDetailReportByReportId();
  }

  getDetailReportByReportId() {
    this.contentModerationService.getDetailReportByReportId(this._id).subscribe({
      next: (res) => {
        this.detail = res;
      }
    });
  }

}
