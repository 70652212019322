import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
  selector: 'app-filtering',
  templateUrl: './filtering.component.html',
  styleUrls: ['./filtering.component.scss']
})
export class FilteringComponent {

  @Input() hiddenDateRange: boolean = false;
  @Output() queryFilter = new EventEmitter(null);

  params = {
    start_date: null,
    end_date: null,
    keyword: null
  };

  onDateRangeSelection(range: { from: NgbDate, to: NgbDate }) {
    const from = this.toDateFormat(range.from);
    const to = this.toDateFormat(range.to);
    this.params.start_date = moment(from).format('yyyy-MM-DD');
    this.params.end_date = moment(to).format('yyyy-MM-DD');
  }

  toDateFormat(dateStruct: NgbDateStruct): Date {
    return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
  }

  search() {
    this.queryFilter.emit(this.params);
  }

}
