import { Component, TemplateRef } from '@angular/core';

import { ToastService } from 'src/services/toast.service';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-toasts',
	standalone: true,
	imports: [NgbToastModule, NgIf, NgTemplateOutlet, NgFor, SharedModule, TranslateModule],
	template: `
	<ngb-toast 
		*ngFor="let toast of toastService.toasts"
		[class]="toast.classname"
		[autohide]="true"
		[delay]="toast.delay || 5000"
		(hidden)="toastService.remove(toast)">

		<div class="d-flex justify-content-between gap-4">

			<div class="d-flex gap-4">
				<app-svg-icon color="white" path="assets/icons/alert-circle.svg" style="--size: 20px;"></app-svg-icon>

				<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
					<ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
				</ng-template>

				<ng-template #text>{{ toast.textOrTpl | translate }}</ng-template>
			</div>
			<button type="button" class="rounded-circle border-0 p-0 bg-transparent" (click)="toastService.remove(toast)">
				<app-svg-icon color="white" path="assets/icons/close.svg" style="--size: 20px;"></app-svg-icon>
			</button>
		</div>
		
	</ngb-toast>`,
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsContainer {

	constructor(public toastService: ToastService) { }

	isTemplate(toast) {
		return toast.textOrTpl instanceof TemplateRef;
	}
}