<div class="sidebar-container">

    <div class="text-center border-bottom pt-lg-6 pb-7">
        <a class="logo-box text-decoration-none text-primary" routerLink="/">
            <img src="assets/icons/logo.svg" alt="Logo image" class="mb-4">
            <h4 class="mb-0"> {{ 'SIDEBAR.TEXT' | translate }}</h4>
        </a>
    </div>

    <div class="menu-container mt-7">
        <ng-container *ngFor="let menu of MenuItem">

            <!-- parent has children -->
            <ng-container *ngIf="menu?.children?.length > 0; else noChildren">
                <div ngbAccordion>
                    <div ngbAccordionItem [collapsed]="!isChildActive(menu?.children)" class="border-0">
                        <div ngbAccordionHeader [class.active]="isChildActive(menu?.children)">
                            <button ngbAccordionButton>
                                <div class="collapse-title d-flex gap-4 align-items-center w-100 rounded-2">
                                    <app-svg-icon [path]="menu.icon"></app-svg-icon>
                                    <span> {{ menu.title | translate }} </span>
                                </div>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <ng-container *ngFor="let child of menu?.children">
                                        <div [routerLink]="child.route" routerLinkActive="active" class="menu-item my-1">
                                            <div class="layer-menu accordion-children d-flex gap-4 align-items-center w-100 rounded-2">
                                                <span> {{ child.title | translate }} </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- has no children -->
            <ng-template #noChildren>
                <div [routerLink]="menu.route" routerLinkActive="active" class="menu-item mb-1">
                    <div class="layer-menu d-flex gap-4 align-items-center w-100 rounded-2 px-5">
                        <app-svg-icon [path]="menu.icon"></app-svg-icon>
                        <span> {{ menu.title | translate }} </span>
                    </div>
                </div>
            </ng-template>

        </ng-container>
    </div>

</div>