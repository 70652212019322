import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from 'src/models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseCrudService<any>{

    constructor(injector: Injector) {
        super(injector);
        this.path = `${environment.portal_url}/users/profile`;
    }

    getAboutUser(): Observable<User> {
        return this.requestService.getJSON(`${this.path}/about`);
    }

}